@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    grid-area: content;    
    padding: var(--spacing-core-6);
    background-color: var(--color-background-primary-default);
    border-radius: var(--border-radius-core-l);

    @include mq.mq($from: medium) {
        padding: var(--spacing-core-8);
    }

    @include mq.mq($from: large) {
        border-radius: unset;
        background-color: transparent;
        padding: 0;
    }
}
