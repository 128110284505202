@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    grid-area: aside;
    width: 100%;
    border-radius: var(--border-radius-core-l);
    padding: var(--spacing-core-6);
    background-color: var(--color-background-primary-default);

    @include mq.mq($from: medium) {        
        padding: var(--spacing-core-8);  
    }

    @include mq.mq($from: large) {        
        padding: var(--spacing-core-12);
        background-color: var(--color-background-secondary-default);      
    }
}
