@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    background-color: transparent;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    transition: box-shadow .2s ease-in-out;
    text-decoration: none;
    padding: var(--spacing-core-2) 0;
    color: var(--color-foreground-primary-default);

    @include mq.mq($from: medium) {
        flex-direction: column;
        padding: var(--spacing-core-4);

        &:hover,
        &:focus-visible {
            box-shadow: 
                0 32px 60px rgb(0 0 0 / 7%),
                0 20px 24.1177px rgb(0 0 0 / 4.6%),
                0 8px 10.0172px rgb(0 0 0 / 3.5%),
                0 3px 3.6230px rgb(0 0 0 / 2.4%);

            img {
                transform: translateY(calc(var(--spacing-core-4) * -1));
            }
        }
    }
}

.thumbnail {
    display: flex;
    justify-content: center;
    background-color: var(--color-background-secondary-default);
    height: 88px;
    border-radius: var(--spacing-core-2);
    overflow: hidden;

    @include mq.mq($until: small) {
        aspect-ratio: 1 / 1;
        flex-shrink: 0;
    }

    @include mq.mq($from: small) {
        height: 112px;
    }

    @include mq.mq($from: medium) {
        padding: var(--spacing-core-8) var(--spacing-core-2) 0 var(--spacing-core-2);
        height: 208px;
    }

    img {
        transition: transform 0.25s;
        max-width: 200px;
        width: 100%;
        height: max-content;
        object-fit: cover;
        object-position: top;

        @include mq.mq($from: medium) {
            border-radius: var(--spacing-core-2) var(--spacing-core-2) 0 0;
        }
    }
}

.body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include mq.mq($until: small) {
        padding-left: var(--spacing-core-4);
    }

    @include mq.mq($from: medium) {
        padding-top: var(--spacing-core-4);
    }
}

.button {
    width: var(--sizing-core-8);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-secondary-default);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    pointer-events: none;
    color: var(--color-foreground-primary-default);
}
