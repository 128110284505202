@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: column;    
    gap: var(--spacing-core-8);
    width: 100%;

    @include mq.mq($from: large) {
        display: grid;
        grid-template:
            "aside content" 
            "aside content" 
            "aside footer";
        grid-template-columns: 395px 1fr;
        gap: var(--spacing-core-6) var(--spacing-core-12);
        padding: var(--spacing-core-12);
        background-color: var(--color-background-primary-default);
    }
}
