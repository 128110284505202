$border-radius: var(--spacing-core-24);

.image {
    width: 100%;
    object-fit: cover;
}

.imageLeft {
    border-radius: $border-radius 0 0 0;
}

.imageRight {
    border-radius: 0 $border-radius 0 0;
}

.spacer {
    &-left {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 85%;
        background: #f7f7f7;
        border-radius: 96px 0 0;
        z-index: -1;
    }

    &-right {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 85%;
        background: #f7f7f7;
        border-radius: 0 96px 0 0;
        z-index: -1;
    }
}
