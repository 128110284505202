@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    text-align: center;
    margin: var(--spacing-core-6) auto;
}

.shareDesktopContainer {
    position: relative;
}

.category {
    display: inline-block;
    margin-bottom: 0;
}

.shareDesktop {
    position: absolute;
    right: 0;
    bottom: calc(var(--spacing-core-2) * -1);

    @include mq.mq($until: small) {
        display: none !important;
    }
}

.shareMobile {
    margin: var(--spacing-core-6) auto;

    @include mq.mq($from: medium) {
        display: none !important;
    }
}

.video {
    max-width: 1196px;
    margin: 0 auto;
}



