@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover {
        .playCircle {
            background: rgb(0 0 0 / 50%);
        }
    }
}

.outline {
    outline: none;
}

.image {
    position: relative;
    width: 100%;
}

.playCircle {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 2px solid var(--color-background-primary-default);
    transform: translate(-50%, -50%);
    transition: background .2s;
    background: rgb(0 0 0 / 30%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    border-radius: 32px;

    @include mq.mq($from: medium) {
        height: 72px;
        width: 72px;
        border-radius: 36px;
    }

    @include mq.mq($from: large) {
        height: 96px;
        width: 96px;
        border-radius: 48px;
    }
}

.playIcon {
    width: 20px;
    height: 20px;
    margin-left: 4px;

    @include mq.mq($from: large) {
        width: 25px;
        height: 25px;
        margin-left: 6px;
    }
}
