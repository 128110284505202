@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.header {
    margin-bottom: var(--sizing-core-6);
}

.year {
    font-weight: normal;
    margin: var(--sizing-core-4) 0;
}

.grid {
    display: grid;
    grid-template-columns: 1fr;
    
    @include mq.mq($from: medium) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq.mq($from: large) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include mq.mq($from: extra-large) {
        grid-template-columns: repeat(5, 1fr);
    }
}
