@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-6);
}

.root {
    padding-left: 0;
    padding-right: 0;

    @media print {
        display: none;
    }
}
