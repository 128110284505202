.root {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.thirdPartyPlayer {
    height: 100%;
    overflow: hidden;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
